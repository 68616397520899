import moment from 'moment';
import React from 'react';
const { useEffect, useRef, useState } = React;

function Countdown({ eventTime }) {
  const countdown = useRef(null);
  const [timeDuration, settimeDuration] = useState('');

  const timer = async () => {
    let countDownDate = eventTime;

    let now = moment().unix();
    let distance = countDownDate - now;
    var duration = toDaysMinutesSeconds(distance);
    settimeDuration(duration)
  }

  function toDaysMinutesSeconds(totalSeconds) {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    return `${days} : ${hours} : ${minutes} : ${seconds}`.replace(/,\s*$/, '');
  }

  useEffect(() => {
    countdown.current = setInterval(timer, 1000);

    return () => {
      clearInterval(countdown.current);
    }
  }, [eventTime]);

  return (
    <div>
      {timeDuration
        ? <span>{timeDuration}</span>
        : <span>00 : 00 : 00</span>
      }
    </div>
  )
}

export default Countdown;