import React, { useState, useEffect, useLayoutEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import { VscLiveShare } from "react-icons/vsc";
import { HiOutlineRectangleStack } from "react-icons/hi2";
import { BiDroplet } from "react-icons/bi";
import { TbAlertTriangle } from "react-icons/tb";
import { IoMdTimer } from "react-icons/io";
import CountDown5 from './Countdown5'
import CountDown4 from './Countdown4'
import CountDown3 from './Countdown3'
import CountDown2 from './Countdown2'
import CountDown1 from './Countdown1'
import axios from "axios";
import Navbar from "./Navbar";
import SideBar from "./SideBar";
import {
  getLP_1Address,
  getLP_2Address,
  getLP_3Address,
  getLP_4Address,
  getLP_5Address,
  getStakingAddress
} from "../utils/addressHelpers";
import {
  useHundredBusd,
  useLP_1,
  useLP_2,
  useLP_3,
  useLP_4,
  useLP_5,
  useLP_6,
  useStaking
} from "../hooks/useContract";
import useWeb3 from "../hooks/useWeb3";
import { useWallet } from "@binance-chain/bsc-use-wallet";

function Pools() {
  const [loading, setLoading] = useState(false);
  const [eventTime, setEventTime] = useState(1669500120);
  const [bnbprice, setbnbprice] = useState(0);
  const [stakedInput, setstakedInput] = useState("");
  const [currentPool, setcurrentPool] = useState("");
  const [APR, setAPR] = useState([0, 0, 0, 0, 0, 0]);
  const [liquidity, setLiquidity] = useState([0, 0, 0, 0, 0, 0]);
  const [LP1, setLP1] = useState({
    id: 0,
    name: "BYAS-BUSD",
    image: "/2.png",
    getReserve: "",
    price: "",
    userInfo: "",
    share: "",
    approve: "",
    balance: "",
    totalStaked: "",
    unStake: "",
    pendingToken: ""
  });
  const [LP2, setLP2] = useState({
    id: 1,
    name: "DXO-BUSD",
    image: "/3.png",
    getReserve: "",
    price: "",
    userInfo: "",
    share: "",
    approve: "",
    balance: "",
    totalStaked: "",
    unStake: "",
    pendingToken: ""
  });
  const [LP3, setLP3] = useState({
    id: 2,
    name: "BTC-DXO",
    image: "/4.png",
    getReserve: "",
    price: "",
    userInfo: "",
    share: "",
    approve: "",
    balance: "",
    totalStaked: "",
    unStake: "",
    pendingToken: ""
  });
  const [LP4, setLP4] = useState({
    id: 3,
    name: "BNB-DXO",
    image: "/5.png",
    getReserve: "",
    price: "",
    userInfo: "",
    share: "",
    approve: "",
    balance: "",
    totalStaked: "",
    unStake: "",
    pendingToken: ""
  });
  const [LP5, setLP5] = useState({
    id: 5,
    name: "HUNDRED-liDXO",
    image: "/1.png",
    getReserve: "",
    price: "",
    userInfo: "",
    share: "",
    approve: "",
    balance: "",
    totalStaked: "",
    unStake: "",
    pendingToken: ""
  });
  const [LP6, setLP6] = useState({
    id: 6,
    name: "HUNDRED",
    image: "/images/hundred.jpeg",
    getReserve: "",
    price: "",
    userInfo: "",
    share: "",
    approve: "",
    balance: "",
    totalStaked: "",
    unStake: "",
    pendingToken: ""
  });

  const LP_1Contract = useLP_1();
  const LP_2Contract = useLP_2();
  const LP_3Contract = useLP_3();
  const LP_4Contract = useLP_4();
  const LP_5Contract = useLP_5();
  const LP_6Contract = useLP_6();
  const hundredBusdContract = useHundredBusd();
  const StakingContract = useStaking();
  const web3 = useWeb3();

  const { account } = useWallet();

  let themeName = localStorage.getItem("theme");
  const [theme, setTheme] = useState(themeName ? themeName : "dark");

  const themeHandle = () => {
    const style = document.getElementById("theme-changer");
    if (theme === "dark") {
      style.href = "../themes/light.css";
      localStorage.setItem("theme", "light");
      setTheme("light")
    } else {
      style.href = "../themes/dark.css";
      localStorage.setItem("theme", "dark");
      setTheme("dark")
    }
  };

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})(?!\d))/g, ",");
    return parts.join(".");
  }

  const getApr = async () => {
    let aprArray = []
    let LiquidityArray = []
    await axios
      .get(`https://api.dexscreener.com/latest/dex/pairs/bsc/${getLP_1Address()}`)
      .then(async (response) => {
        let pairs = response.data.pairs ? response.data.pairs : [];
        await axios
          .get(`https://api.dexscreener.com/latest/dex/pairs/bsc/${getLP_2Address()},${getLP_3Address()},${getLP_4Address()},${getLP_5Address()}`)
          .then(async (response) => {
            pairs = [...pairs, ...response.data.pairs];
            await pairs.map(async (data, index) => {
              // let apr = await (((0.17 * data.volume.h24 * 365) / data.liquidity.usd)).toFixed(2);
              let apr = 0;
              if (getLP_1Address() == data.pairAddress) {
                aprArray[0] = apr;
                LiquidityArray[0] = data.liquidity.usd.toFixed(2)
              }
              if (getLP_2Address() == data.pairAddress) {
                aprArray[1] = apr;
                LiquidityArray[1] = data.liquidity.usd.toFixed(2)
              }
              if (getLP_3Address() == data.pairAddress) {
                aprArray[2] = apr;
                LiquidityArray[2] = data.liquidity.usd.toFixed(2)
              }
              if (getLP_4Address() == data.pairAddress) {
                aprArray[3] = apr;
                LiquidityArray[3] = data.liquidity.usd.toFixed(2)
              }
              if (getLP_5Address() == data.pairAddress) {
                aprArray[4] = apr;
                LiquidityArray[4] = data.liquidity.usd.toFixed(2)
              }
            })
            console.log(aprArray, LiquidityArray);
            await setAPR(aprArray);
            await setLiquidity(LiquidityArray);
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getstatsLP1 = async () => {
    let LPReserves = ""
    let reserve = {}
    let userInfo = {}

    let stakingInfo = null;
    let pendingToken = null;
    let getUnStaked = null;
    let LPBalanceOf = null;
    let isApproved = null;
    let share = null;
    let poolInfo = await StakingContract.methods.poolInfo(0).call();
    poolInfo['balance'] = await (parseInt(poolInfo['balance']) / 10 ** 18).toFixed(4);

    if (account) {
      stakingInfo = await StakingContract.methods.userInfo(0, account).call();
      pendingToken = await StakingContract.methods.pendingToken(0, account).call();
      pendingToken = await (parseInt(pendingToken) / 10 ** 18).toFixed(4);
      getUnStaked = await LP_1Contract.methods.balanceOf(account).call();
      getUnStaked = await web3.utils.fromWei(getUnStaked, 'ether');
      getUnStaked = await parseFloat(getUnStaked);
      stakingInfo['amount'] = await web3.utils.fromWei(stakingInfo['amount'], 'ether');
      stakingInfo['amount'] = await parseFloat(stakingInfo['amount']);
      stakingInfo['rewardDebt'] = await (parseInt(stakingInfo['rewardDebt']) / 10 ** 18).toFixed(4);
      stakingInfo['rewardLockedUp'] = await (parseInt(stakingInfo['rewardLockedUp']) / 10 ** 18).toFixed(4);
      userInfo = {
        amount: stakingInfo['amount'],
        rewardDebt: stakingInfo['rewardDebt'],
        rewardLockedUp: stakingInfo['rewardLockedUp'],
        nextHarvestUntil: stakingInfo['nextHarvestUntil']
      }
      LPBalanceOf = await LP_1Contract.methods.balanceOf(account).call();
      isApproved = await LP_1Contract.methods.allowance(account, getStakingAddress()).call();
      isApproved = await web3.utils.fromWei(isApproved, 'ether');
      isApproved = await parseInt(isApproved);
      share = await ((stakingInfo['amount'] / poolInfo['balance']) * 100).toFixed(2);
    }

    LPReserves = await LP_1Contract.methods.getReserves().call();
    LPReserves['0'] = await (parseInt(LPReserves['0']) / 10 ** 18).toFixed(4);
    LPReserves['1'] = await (parseInt(LPReserves['1']) / 10 ** 18).toFixed(4);
    let price = await (LPReserves['1'] / LPReserves['0']).toFixed(6)
    reserve = {
      0: LPReserves['0'],
      1: LPReserves['1']
    }
    await setLP1({ ...LP1, "pendingToken": pendingToken, "getReserve": reserve, "price": price, "userInfo": userInfo, "share": share, "approve": isApproved, "totalStaked": poolInfo['balance'], "balance": LPBalanceOf, "unStake": getUnStaked });
  }

  const getstatsLP2 = async () => {
    let LPReserves = ""
    let reserve = {}
    let userInfo = {}

    let stakingInfo = null;
    let pendingToken = null;
    let getUnStaked = null;
    let LPBalanceOf = null;
    let isApproved = null;
    let share = null;
    let poolInfo = await StakingContract.methods.poolInfo(1).call();
    poolInfo['balance'] = await (parseInt(poolInfo['balance']) / 10 ** 18).toFixed(4);

    if (account) {
      stakingInfo = await StakingContract.methods.userInfo(1, account).call();
      pendingToken = await StakingContract.methods.pendingToken(1, account).call();
      pendingToken = await (parseInt(pendingToken) / 10 ** 18).toFixed(4)
      getUnStaked = await LP_2Contract.methods.balanceOf(account).call();
      getUnStaked = await web3.utils.fromWei(getUnStaked, 'ether');
      getUnStaked = await parseFloat(getUnStaked);
      stakingInfo['amount'] = await web3.utils.fromWei(stakingInfo['amount'], 'ether');
      stakingInfo['amount'] = await parseFloat(stakingInfo['amount']);
      stakingInfo['rewardDebt'] = await (parseInt(stakingInfo['rewardDebt']) / 10 ** 18).toFixed(4);
      stakingInfo['rewardLockedUp'] = await (parseInt(stakingInfo['rewardLockedUp']) / 10 ** 18).toFixed(4);
      userInfo = {
        amount: stakingInfo['amount'],
        rewardDebt: stakingInfo['rewardDebt'],
        rewardLockedUp: stakingInfo['rewardLockedUp'],
        nextHarvestUntil: stakingInfo['nextHarvestUntil']
      }
      // let LPTotalSupply = await LP_2Contract.methods.totalSupply().call();
      LPBalanceOf = await LP_2Contract.methods.balanceOf(account).call();
      isApproved = await LP_2Contract.methods.allowance(account, getStakingAddress()).call();
      isApproved = await web3.utils.fromWei(isApproved, 'ether');
      isApproved = await parseInt(isApproved);
      share = await ((stakingInfo['amount'] / poolInfo['balance']) * 100).toFixed(2);
    }
    LPReserves = await LP_2Contract.methods.getReserves().call();
    LPReserves['0'] = await (parseInt(LPReserves['0']) / 10 ** 18).toFixed(4);
    LPReserves['1'] = await (parseInt(LPReserves['1']) / 10 ** 18).toFixed(4);
    let price = await ((LPReserves['1'] / LPReserves['0'])).toFixed(6)
    reserve = {
      0: LPReserves['0'],
      1: LPReserves['1']
    }
    await setLP2({ ...LP2, "pendingToken": pendingToken, "getReserve": reserve, "price": price, "userInfo": userInfo, "share": share, "approve": isApproved, "totalStaked": poolInfo['balance'], "balance": LPBalanceOf, "unStake": getUnStaked });
  }

  const getstatsLP3 = async () => {
    let LPReserves = ""
    let reserve = {}
    let userInfo = {}

    let stakingInfo = null;
    let pendingToken = null;
    let getUnStaked = null;
    let LPBalanceOf = null;
    let isApproved = null;
    let share = null;
    let poolInfo = await StakingContract.methods.poolInfo(2).call();
    poolInfo['balance'] = await (parseInt(poolInfo['balance']) / 10 ** 18).toFixed(4);

    if (account) {
      stakingInfo = await StakingContract.methods.userInfo(2, account).call();
      pendingToken = await StakingContract.methods.pendingToken(2, account).call();
      pendingToken = await (parseInt(pendingToken) / 10 ** 18).toFixed(4)
      getUnStaked = await LP_3Contract.methods.balanceOf(account).call();
      getUnStaked = await web3.utils.fromWei(getUnStaked, 'ether');
      getUnStaked = await parseFloat(getUnStaked);
      stakingInfo['amount'] = await web3.utils.fromWei(stakingInfo['amount'], 'ether');
      stakingInfo['amount'] = await parseFloat(stakingInfo['amount']);
      stakingInfo['rewardDebt'] = await (parseInt(stakingInfo['rewardDebt']) / 10 ** 18).toFixed(4);
      stakingInfo['rewardLockedUp'] = await (parseInt(stakingInfo['rewardLockedUp']) / 10 ** 18).toFixed(4);
      userInfo = {
        amount: stakingInfo['amount'],
        rewardDebt: stakingInfo['rewardDebt'],
        rewardLockedUp: stakingInfo['rewardLockedUp'],
        nextHarvestUntil: stakingInfo['nextHarvestUntil']
      }
      // let LPTotalSupply = await LP_3Contract.methods.totalSupply().call();
      LPBalanceOf = await LP_3Contract.methods.balanceOf(account).call();
      isApproved = await LP_3Contract.methods.allowance(account, getStakingAddress()).call();
      isApproved = await web3.utils.fromWei(isApproved, 'ether');
      isApproved = await parseInt(isApproved);
      share = await ((stakingInfo['amount'] / poolInfo['balance']) * 100).toFixed(2);
    }
    LPReserves = await LP_3Contract.methods.getReserves().call();
    LPReserves['0'] = await (parseInt(LPReserves['0']) / 10 ** 18).toFixed(4);
    LPReserves['1'] = await (parseInt(LPReserves['1']) / 10 ** 18).toFixed(4);
    let price = await ((LPReserves['1'] / LPReserves['0'])).toFixed(6)
    reserve = {
      0: LPReserves['0'],
      1: LPReserves['1']
    }
    await setLP3({ ...LP3, "pendingToken": pendingToken, "getReserve": reserve, "price": price, "userInfo": userInfo, "share": share, "approve": isApproved, "totalStaked": poolInfo['balance'], "balance": LPBalanceOf, "unStake": getUnStaked });
  }

  const getstatsLP4 = async () => {
    let LPReserves = ""
    let reserve = {}
    let userInfo = {}

    let stakingInfo = null;
    let pendingToken = null;
    let getUnStaked = null;
    let LPBalanceOf = null;
    let isApproved = null;
    let share = null;
    let poolInfo = await StakingContract.methods.poolInfo(3).call();
    poolInfo['balance'] = await (parseInt(poolInfo['balance']) / 10 ** 18).toFixed(4);

    if (account) {
      stakingInfo = await StakingContract.methods.userInfo(3, account).call();
      pendingToken = await StakingContract.methods.pendingToken(3, account).call();
      pendingToken = await (parseInt(pendingToken) / 10 ** 18).toFixed(4)
      getUnStaked = await LP_4Contract.methods.balanceOf(account).call();
      getUnStaked = await web3.utils.fromWei(getUnStaked, 'ether');
      getUnStaked = await parseFloat(getUnStaked);
      stakingInfo['amount'] = await web3.utils.fromWei(stakingInfo['amount'], 'ether');
      stakingInfo['amount'] = await parseFloat(stakingInfo['amount']);
      stakingInfo['rewardDebt'] = await (parseInt(stakingInfo['rewardDebt']) / 10 ** 18).toFixed(4);
      stakingInfo['rewardLockedUp'] = await (parseInt(stakingInfo['rewardLockedUp']) / 10 ** 18).toFixed(4);
      userInfo = {
        amount: stakingInfo['amount'],
        rewardDebt: stakingInfo['rewardDebt'],
        rewardLockedUp: stakingInfo['rewardLockedUp'],
        nextHarvestUntil: stakingInfo['nextHarvestUntil']
      }
      // let LPTotalSupply = await LP_4Contract.methods.totalSupply().call();
      LPBalanceOf = await LP_4Contract.methods.balanceOf(account).call();
      isApproved = await LP_4Contract.methods.allowance(account, getStakingAddress()).call();
      isApproved = await web3.utils.fromWei(isApproved, 'ether');
      isApproved = await parseInt(isApproved);
      share = await ((stakingInfo['amount'] / poolInfo['balance']) * 100).toFixed(2);
    }
    LPReserves = await LP_4Contract.methods.getReserves().call();
    LPReserves['0'] = await (parseInt(LPReserves['0']) / 10 ** 18).toFixed(4);
    LPReserves['1'] = await (parseInt(LPReserves['1']) / 10 ** 18).toFixed(4);

    let price = await ((LPReserves['0'] / LPReserves['1'])).toFixed(6)
    reserve = {
      0: LPReserves['0'],
      1: LPReserves['1']
    }
    await setLP4({ ...LP4, "pendingToken": pendingToken, "getReserve": reserve, "price": price, "userInfo": userInfo, "share": share, "approve": isApproved, "totalStaked": poolInfo['balance'], "balance": LPBalanceOf, "unStake": getUnStaked });
  }

  const getstatsLP5 = async () => {
    let LPReserves = ""
    let reserve = {}
    let userInfo = {}

    let stakingInfo = null;
    let pendingToken = null;
    let getUnStaked = null;
    let LPBalanceOf = null;
    let isApproved = null;
    let share = null;
    let poolInfo = await StakingContract.methods.poolInfo(5).call();
    poolInfo['balance'] = await (parseInt(poolInfo['balance']) / 10 ** 18).toFixed(4);

    if (account) {
      stakingInfo = await StakingContract.methods.userInfo(5, account).call();
      pendingToken = await StakingContract.methods.pendingToken(5, account).call();
      pendingToken = await (parseInt(pendingToken) / 10 ** 18).toFixed(4)
      getUnStaked = await LP_5Contract.methods.balanceOf(account).call();
      getUnStaked = await web3.utils.fromWei(getUnStaked, 'ether');
      getUnStaked = await parseFloat(getUnStaked);
      stakingInfo['amount'] = await web3.utils.fromWei(stakingInfo['amount'], 'ether');
      stakingInfo['amount'] = await parseFloat(stakingInfo['amount']);
      stakingInfo['rewardDebt'] = await (parseInt(stakingInfo['rewardDebt']) / 10 ** 18).toFixed(4);
      stakingInfo['rewardLockedUp'] = await (parseInt(stakingInfo['rewardLockedUp']) / 10 ** 18).toFixed(4);
      userInfo = {
        amount: stakingInfo['amount'],
        rewardDebt: stakingInfo['rewardDebt'],
        rewardLockedUp: stakingInfo['rewardLockedUp'],
        nextHarvestUntil: stakingInfo['nextHarvestUntil']
      }
      // let LPTotalSupply = await LP_5Contract.methods.totalSupply().call();
      LPBalanceOf = await LP_5Contract.methods.balanceOf(account).call();
      isApproved = await LP_5Contract.methods.allowance(account, getStakingAddress()).call();
      isApproved = await web3.utils.fromWei(isApproved, 'ether');
      isApproved = await parseInt(isApproved);
      share = await ((stakingInfo['amount'] / poolInfo['balance']) * 100).toFixed(2);
      share = share ? share : 0
    }
    LPReserves = await LP_5Contract.methods.getReserves().call();
    LPReserves['0'] = await (parseInt(LPReserves['0']) / 10 ** 18).toFixed(4);
    LPReserves['1'] = await (parseInt(LPReserves['1']) / 10 ** 18).toFixed(4);
    let price = await ((LPReserves['1'] / LPReserves['0'])).toFixed(6)
    reserve = {
      0: LPReserves['0'],
      1: LPReserves['1']
    }
    await setLP5({ ...LP5, "pendingToken": pendingToken, "getReserve": reserve, "price": price, "userInfo": userInfo, "share": share, "approve": isApproved, "totalStaked": poolInfo['balance'], "balance": LPBalanceOf, "unStake": getUnStaked });
  }

  const getstatsLP6 = async () => {
    let LPReserves = ""
    let reserve = {}
    let userInfo = {}

    let stakingInfo = null;
    let pendingToken = null;
    let getUnStaked = null;
    let LPBalanceOf = null;
    let isApproved = null;
    let share = null;
    let poolInfo = await StakingContract.methods.poolInfo(6).call();
    poolInfo['balance'] = await (parseInt(poolInfo['balance']) / 10 ** 18).toFixed(4);
    let price = await axios.get("https://api.dexscreener.com/latest/dex/tokens/0xB66279cE85Ff79dF18901946e65216777b093967");

    if (account) {
      stakingInfo = await StakingContract.methods.userInfo(6, account).call();
      pendingToken = await StakingContract.methods.pendingToken(6, account).call();
      pendingToken = await (parseInt(pendingToken) / 10 ** 18).toFixed(4)
      getUnStaked = await LP_6Contract.methods.balanceOf(account).call();
      getUnStaked = await web3.utils.fromWei(getUnStaked, 'ether');
      getUnStaked = await parseFloat(getUnStaked);
      stakingInfo['amount'] = await web3.utils.fromWei(stakingInfo['amount'], 'ether');
      stakingInfo['amount'] = await parseFloat(stakingInfo['amount']);
      stakingInfo['rewardDebt'] = await (parseInt(stakingInfo['rewardDebt']) / 10 ** 18).toFixed(4);
      stakingInfo['rewardLockedUp'] = await (parseInt(stakingInfo['rewardLockedUp']) / 10 ** 18).toFixed(4);
      userInfo = {
        amount: stakingInfo['amount'],
        rewardDebt: stakingInfo['rewardDebt'],
        rewardLockedUp: stakingInfo['rewardLockedUp'],
        nextHarvestUntil: stakingInfo['nextHarvestUntil']
      }
      // let LPTotalSupply = await LP_5Contract.methods.totalSupply().call();
      LPBalanceOf = await LP_6Contract.methods.balanceOf(account).call();
      isApproved = await LP_6Contract.methods.allowance(account, getStakingAddress()).call();
      isApproved = await web3.utils.fromWei(isApproved, 'ether');
      isApproved = await parseInt(isApproved);
      share = await ((stakingInfo['amount'] / poolInfo['balance']) * 100).toFixed(2);
      share = share ? share : 0
    }
    await setLP6({ ...LP6, "pendingToken": pendingToken, "price": price.data.pairs[0].priceUsd, "userInfo": userInfo, "share": share, "approve": isApproved, "totalStaked": poolInfo['balance'], "balance": LPBalanceOf, "unStake": getUnStaked });
  }

  function toPlainString(num) {
    console.log(num)
    return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      });
  }

  const getBnbPrice = async () => {
    await axios
      .get(`https://api-testnet.bscscan.com/api?module=stats&action=bnbprice&apikey=7Y59T2RTXIXZQ88USF5N935XJJ6Z1D6MD4`)
      .then(async (response) => {
        let price = await (response.data.result.ethusd ? parseInt(response.data.result.ethusd) : 0);
        await setbnbprice(price)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  async function approveToken(pool) {
    let token = await getStakingAddress();
    const gasPriceInWei = web3.utils.toWei('10', 'gwei');
    if (pool == 0) {
      await LP_1Contract.methods
        .approve(
          token,
          "1000000000000000000000000000000000000000000000000000000000000000"
        )
        .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
          console.log(error, result)
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          setTimeout(() => {
            getstatsLP1()
          }, 2000);
        });
    } else if (pool == 1) {
      await LP_2Contract.methods
        .approve(
          token,
          "1000000000000000000000000000000000000000000000000000000000000000"
        )
        .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
          console.log(error, result)
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          setTimeout(() => {
            getstatsLP2()
          }, 2000);
        });
    } else if (pool == 2) {
      await LP_3Contract.methods
        .approve(
          token,
          "1000000000000000000000000000000000000000000000000000000000000000"
        )
        .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
          console.log(error, result)
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          setTimeout(() => {
            getstatsLP3()
          }, 2000);
        });
    } else if (pool == 3) {
      await LP_4Contract.methods
        .approve(
          token,
          "1000000000000000000000000000000000000000000000000000000000000000"
        )
        .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
          console.log(error, result)
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          setTimeout(() => {
            getstatsLP4()
          }, 2000);
        });
    } else if (pool == 5) {
      await LP_5Contract.methods
        .approve(
          token,
          "1000000000000000000000000000000000000000000000000000000000000000"
        )
        .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
          console.log(error, result)
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          setTimeout(() => {
            getstatsLP5()
          }, 2000);
        });
    } else if (pool == 6) {
      await LP_6Contract.methods
        .approve(
          token,
          "1000000000000000000000000000000000000000000000000000000000000000"
        )
        .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
          console.log(error, result)
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          setTimeout(() => {
            getstatsLP6()
          }, 2000);
        });
    } else {
      return
    }
  }

  async function claim(pool) {
    const gasPriceInWei = web3.utils.toWei('10', 'gwei');
    await StakingContract.methods
      .harvest(
        pool
      )
      .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
        console.log(error, result)
      });
  }

  async function claimAll(amount, pool) {
    try {
      const gasPriceInWei = web3.utils.toWei('10', 'gwei');
      amount = await parseFloat(amount)
      amount = await toPlainString(amount);
      let stakedAmount = await web3.utils.toWei(amount, 'ether');
      console.log(stakedAmount)
      await StakingContract.methods
        .withdrawAndHarvest(
          pool,
          stakedAmount
        )
        .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
          console.log(error, result)
        });
    } catch (error) {
      console.log(error)
    }
  }

  async function stakeBtn(pool) {
    const gasPriceInWei = web3.utils.toWei('10', 'gwei');
    let stakedAmount = await web3.utils.toWei(stakedInput.toString(), 'ether');
    await StakingContract.methods
      .deposit(
        pool,
        stakedAmount
      )
      .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
        console.log(error, result)
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        setTimeout(() => {
          getAllData()
        }, 1000);
      });
    await document.querySelector("button#btn-close1").click();
    await setstakedInput("");
  }

  async function unStakeBtn(pool) {
    const gasPriceInWei = web3.utils.toWei('10', 'gwei');
    let stakedAmount = await web3.utils.toWei(stakedInput.toString(), 'ether');
    await StakingContract.methods
      .withdraw(
        pool,
        stakedAmount
      )
      .send({ from: account, value: 0, gasPrice: gasPriceInWei }, (error, result) => {
        console.log(error, result)
      });
    await document.querySelector("button#btn-close2").click();
    await setstakedInput("");
  }

  const getAllData = async () => {
    // await getBnbPrice()
    await getstatsLP1()
    await getstatsLP2()
    await getstatsLP3()
    await getstatsLP4()
    // await getstatsLP5()
    await getstatsLP6()
  }

  const changeStakedAmount = async (value) => {
    let check = await value.split('.');

    if (check[1]) {
      if (check[1].length <= 8) setstakedInput(value)
    } else {
      setstakedInput(value)
    }
  }

  const firstLoad = async () => {
    try {
      await setLoading(true)
      await getAllData()
      await getApr()
      await setLoading(false)
    } catch (error) {
      await setLoading(false)
    }
  }

  useEffect(() => {
    // if (account) {
    firstLoad()
    window.intervalControl = setInterval(
      () => {
        getAllData()
      },
      45000
    )
    return () => {
      clearInterval(window.intervalControl)
    }
    // }
  }, [account]);

  // useLayoutEffect(() => {
  //   getBnbPrice()
  // }, []);

  return (
    <>
      <Navbar search={false} theme={theme} themeHandle={themeHandle} />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-lg-block d-none">
            <SideBar />
          </div>
          <div className="col-lg-10">
            <div className="container-fluid mt-5">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>Pools</h3>
              </div>
              <div className="accordion accordion-flush accord" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      <div className="d-flex align-items-center mb-3 col-lg-4">
                        <img
                          src="/2.png"
                          alt="coin"
                          className="bg-white"
                          style={{ height: "4em", borderRadius: "15px" }}
                        />
                        <div className="ms-2 me-4 sm_font ">
                          <p className="mb-1">BYAS-BUSD</p>
                          <div>
                            <span className="color1">Liquidity: </span>
                            <span className="text-light">${liquidity[0]}</span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 sm_font col-lg-1 col-6">
                        <p className="mb-1">{LP1.userInfo["amount"] ? LP1.userInfo["amount"].toFixed(4) : 0}</p>
                        <p className="color1 mb-0">Staked</p>
                      </div>
                      <div className="mb-3 sm_font col-lg-1 col-6" title="All yield farming APRs are unknown. This APR is simply a reflection of the last 24 hours of activity and doesn&#39;t constitute or imply a guaranteed rate of return">
                        <p className="mb-1 color2">
                          {/* <b>{APR[0]}%</b> */}
                          <b>450+%</b>
                        </p>
                        <p className="color1 mb-0">APR</p>
                      </div>
                      <div className="d-flex my-4 mx-lg-4 mx-md-4 header_btns">
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Claim
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Stake
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_orange">
                          Add liquidity
                        </button>
                      </div>
                      <button className="btn btn-sm py-lg-2 icn_btn angle_down">
                        <FaAngleDown className="size_icn" />
                      </button>
                      <button className="btn btn-sm py-lg-2 icn_btn d-none angle_up">
                        <FaAngleUp className="size_icn" />
                      </button>
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="container-fluid">
                        <div className="row mt-5 font1">

                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <img
                                    src="/Crank.jpg"
                                    width="22px"
                                    className="me-3 borderR-5"
                                  />
                                  <span>Total pooled BYAS</span>
                                </div>
                                <div className="mb-3">
                                  <img
                                    src="/4687 - BU.png"
                                    width="22px"
                                    className="me-3 borderR-5"
                                  />
                                  <span>Total pooled BUSD</span>
                                </div>
                                <div className="mb-3">
                                  <ImPriceTag size={20} className="me-3" />
                                  <span>Price</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP1.getReserve["0"] ? LP1.getReserve["0"] : 0}</div>
                                <div className="mb-3 text-right">{LP1.getReserve["1"] ? LP1.getReserve["1"] : 0}</div>
                                <div className="mb-3 text-right">
                                  1 <span className="color1">BYAS</span> = {LP1.price ? LP1.price : 0}{" "}
                                  <span className="color1">BUSD</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Total staked</span>
                                </div>
                                <div className="mb-3">
                                  <IoMdTimer size={20} className="me-3" />
                                  <span>Time left</span>
                                </div>
                                <div className="mb-3">
                                  <VscLiveShare size={20} className="me-3" />
                                  <span>Your pool share</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP1.totalStaked ? LP1.totalStaked : 0}</div>
                                <div className="mb-3 text-right">{parseInt(LP1.userInfo["nextHarvestUntil"]) ? <CountDown1 eventTime={parseInt(LP1.userInfo["nextHarvestUntil"])} /> : "00 : 00 : 00"}</div>
                                <div className="mb-3 text-right">{LP1.share && LP1.share != "NaN" ? LP1.share : 0}%</div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your unstaked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your staked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your earned</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP1.unStake ? LP1.unStake.toFixed(8) : 0}</div>
                                <div className="mb-3 text-right">{LP1.userInfo["amount"] ? LP1.userInfo["amount"].toFixed(4) : 0}</div>
                                <div className="mb-3 text-right">
                                  <span className="color2">{(LP1.pendingToken ? LP1.pendingToken : 0)}</span>
                                  <span className="color1"> BYAS</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col">
                            <a href={`https://pancakeswap.finance/add/0x5DE56c867692277fAaF6756D6d30Eba2086E948E/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56`} className="btn my-2 btn_orange2" target="_blank">
                              Add liquidity
                            </a>
                          </div>
                          <div className="col">
                            {LP1.approve
                              ? <button className="btn my-2 btn_gray_white2" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => { setcurrentPool(LP1); setstakedInput("") }}>
                                Stake LP Tokens
                              </button>
                              : <button className="btn my-2 btn_gray_white2" onClick={() => approveToken(0)}>
                                Approve LP Tokens
                              </button>
                            }

                          </div>
                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claim(0)}
                              title="Clicking this button will claim your rewards but keep tokens staked for another session."
                            >
                              Claim BYAS
                            </button>
                          </div>
                          <div className="col">
                            <button className="btn my-2 btn_gray_white2" disabled={(LP1.userInfo["amount"] ? parseFloat(LP1.userInfo["amount"]) : 0) ? false : true} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => { setcurrentPool(LP1); setstakedInput("") }}>
                              Unstake
                            </button>
                          </div>
                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claimAll((LP1.userInfo["amount"] ? LP1.userInfo["amount"] : 0), 0)}
                              title="Clicking this button will claim your rewards and your staked tokens"
                            >
                              Claim All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      <div className="d-flex align-items-center mb-3 col-lg-4">
                        <img
                          src="/3.png"
                          alt="coin"
                          className="bg-white"
                          style={{ height: "4em", borderRadius: "15px" }}
                        />
                        <div className="ms-2 me-4 sm_font">
                          <p className="mb-1">DXO-BUSD</p>
                          <div>
                            <span className="color1">Liquidity: </span>
                            <span className="text-light">${liquidity[1]}</span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 sm_font col-lg-1 col-6">
                        <p className="mb-1">{LP2.userInfo["amount"] ? LP2.userInfo["amount"].toFixed(4) : 0}</p>
                        <p className="color1 mb-0">Staked</p>
                      </div>
                      <div className="mb-3 sm_font col-lg-1 col-6" title="All yield farming APRs are unknown. This APR is simply a reflection of the last 24 hours of activity and doesn&#39;t constitute or imply a guaranteed rate of return">
                        <p className="mb-1 color2">
                          {/* <b>{APR[1]}%</b> */}
                          <b>300+%</b>
                        </p>
                        <p className="color1 mb-0">APR</p>
                      </div>
                      <div className="d-flex my-4 mx-lg-4 mx-md-4 header_btns">
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Claim
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Stake
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_orange">
                          Add liquidity
                        </button>
                      </div>
                      <button className="btn btn-sm py-lg-2 icn_btn angle_down">
                        <FaAngleDown className="size_icn" />
                      </button>
                      <button className="btn btn-sm py-lg-2 icn_btn d-none angle_up">
                        <FaAngleUp className="size_icn" />
                      </button>
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="container-fluid">
                        <div className="row mt-5 font1">

                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <img
                                    src="/dxtc.png"
                                    width="22px"
                                    className="me-3 borderR-5 bg-white"
                                  />
                                  <span>Total pooled DXO</span>
                                </div>
                                <div className="mb-3">
                                  <img
                                    src="/4687 - BU.png"
                                    width="22px"
                                    className="me-3 borderR-5"
                                  />
                                  <span>Total pooled BUSD</span>
                                </div>
                                <div className="mb-3">
                                  <ImPriceTag size={20} className="me-3" />
                                  <span>Price</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP2.getReserve["0"] ? LP2.getReserve["0"] : 0}</div>
                                <div className="mb-3 text-right">{LP2.getReserve["1"] ? LP2.getReserve["1"] : 0}</div>
                                <div className="mb-3 text-right">
                                  1 <span className="color1">DXO</span> = {LP2.price ? LP2.price : 0}{" "}
                                  <span className="color1">BUSD</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Total staked</span>
                                </div>
                                <div className="mb-3">
                                  <IoMdTimer size={20} className="me-3" />
                                  <span>Time left</span>
                                </div>
                                <div className="mb-3">
                                  <VscLiveShare size={20} className="me-3" />
                                  <span>Your pool share</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP2.totalStaked ? LP2.totalStaked : 0}</div>
                                <div className="mb-3 text-right">{parseInt(LP2.userInfo["nextHarvestUntil"]) ? <CountDown2 eventTime={parseInt(LP2.userInfo["nextHarvestUntil"])} /> : "00 : 00 : 00"}</div>
                                <div className="mb-3 text-right">{LP2.share && LP2.share != "NaN" ? LP2.share : 0}%</div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your unstaked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your staked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your earned</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP2.unStake ? LP2.unStake.toFixed(8) : 0}</div>
                                <div className="mb-3 text-right">{LP2.userInfo["amount"] ? LP2.userInfo["amount"].toFixed(4) : 0}</div>
                                <div className="mb-3 text-right">
                                  <span className="color2">{(LP2.pendingToken ? LP2.pendingToken : 0)}</span>
                                  <span className="color1"> BYAS</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col">
                            <a href={`https://pancakeswap.finance/add/0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56`} className="btn my-2 btn_orange2" target="_blank">
                              Add liquidity
                            </a>
                          </div>
                          <div className="col">
                            {LP2.approve
                              ? <button className="btn my-2 btn_gray_white2" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => { setcurrentPool(LP2); setstakedInput("") }}>
                                Stake LP Tokens
                              </button>
                              : <button className="btn my-2 btn_gray_white2" onClick={() => approveToken(1)}>
                                Approve LP Tokens
                              </button>
                            }

                          </div>
                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claim(1)}
                              title="Clicking this button will claim your rewards but keep tokens staked for another session."
                            >
                              Claim BYAS
                            </button>
                          </div>
                          <div className="col">
                            <button className="btn my-2 btn_gray_white2" disabled={(LP2.userInfo["amount"] ? parseFloat(LP2.userInfo["amount"]) : 0) ? false : true} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => { setcurrentPool(LP2); setstakedInput("") }}>
                              Unstake
                            </button>
                          </div>

                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claimAll((LP2.userInfo["amount"] ? LP2.userInfo["amount"] : 0), 1)}
                              title="Clicking this button will claim your rewards and your staked tokens"
                            >
                              Claim All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      <div className="d-flex align-items-center mb-3 col-lg-4">
                        <img
                          src="/4.png"
                          alt="coin"
                          className="bg-white"
                          style={{ height: "4em", borderRadius: "15px" }}
                        />
                        <div className="ms-2 me-4 sm_font">
                          <p className="mb-1">BTC-DXO</p>
                          <div>
                            <span className="color1">Liquidity: </span>
                            <span className="text-light">${liquidity[2]}</span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 sm_font col-lg-1 col-6">
                        <p className="mb-1">{LP3.userInfo["amount"] ? LP3.userInfo["amount"].toFixed(4) : 0}</p>
                        <p className="color1 mb-0">Staked</p>
                      </div>
                      <div className="mb-3 sm_font col-lg-1 col-6" title="All yield farming APRs are unknown. This APR is simply a reflection of the last 24 hours of activity and doesn&#39;t constitute or imply a guaranteed rate of return">
                        <p className="mb-1 color2">
                          {/* <b>{APR[2]}%</b> */}
                          <b>300+%</b>
                        </p>
                        <p className="color1 mb-0">APR</p>
                      </div>
                      <div className="d-flex my-4 mx-lg-4 mx-md-4 header_btns">
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Claim
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Stake
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_orange">
                          Add liquidity
                        </button>
                      </div>
                      <button className="btn btn-sm py-lg-2 icn_btn angle_down">
                        <FaAngleDown className="size_icn" />
                      </button>
                      <button className="btn btn-sm py-lg-2 icn_btn d-none angle_up">
                        <FaAngleUp className="size_icn" />
                      </button>
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="container-fluid">
                        <div className="row mt-5 font1">

                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <img
                                    src="/Bitcoin.png"
                                    width="22px"
                                    className="me-3 borderR-5"
                                  />
                                  <span>Total pooled BTC</span>
                                </div>
                                <div className="mb-3">
                                  <img
                                    src="/dxtc.png"
                                    width="22px"
                                    className="me-3 borderR-5 bg-white"
                                  />
                                  <span>Total pooled DXO</span>
                                </div>
                                <div className="mb-3">
                                  <ImPriceTag size={20} className="me-3" />
                                  <span>Price</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP3.getReserve["0"] ? LP3.getReserve["0"] : 0}</div>
                                <div className="mb-3 text-right">{LP3.getReserve["1"] ? LP3.getReserve["1"] : 0}</div>
                                <div className="mb-3 text-right">
                                  1 <span className="color1">BTC</span> = {LP3.price ? LP3.price : 0}{" "}
                                  <span className="color1">DXO</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Total staked</span>
                                </div>
                                <div className="mb-3">
                                  <IoMdTimer size={20} className="me-3" />
                                  <span>Time left</span>
                                </div>
                                <div className="mb-3">
                                  <VscLiveShare size={20} className="me-3" />
                                  <span>Your pool share</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP3.totalStaked ? LP3.totalStaked : 0}</div>
                                <div className="mb-3 text-right">{parseInt(LP3.userInfo["nextHarvestUntil"]) ? <CountDown3 eventTime={parseInt(LP3.userInfo["nextHarvestUntil"])} /> : "00 : 00 : 00"}</div>
                                <div className="mb-3 text-right">{LP3.share && LP3.share != "NaN" ? LP3.share : 0}%</div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your unstaked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your staked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your earned</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP3.unStake ? LP3.unStake.toFixed(8) : 0}</div>
                                <div className="mb-3 text-right">{LP3.userInfo["amount"] ? LP3.userInfo["amount"].toFixed(4) : 0}</div>
                                <div className="mb-3 text-right">
                                  <span className="color2">{(LP3.pendingToken ? LP3.pendingToken : 0)}</span>
                                  <span className="color1"> BYAS</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col">
                            <a href={`https://pancakeswap.finance/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4`} className="btn my-2 btn_orange2" target="_blank">
                              Add liquidity
                            </a>
                          </div>
                          <div className="col">
                            {LP3.approve
                              ? <button className="btn my-2 btn_gray_white2" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => { setcurrentPool(LP3); setstakedInput("") }}>
                                Stake LP Tokens
                              </button>
                              : <button className="btn my-2 btn_gray_white2" onClick={() => approveToken(2)}>
                                Approve LP Tokens
                              </button>
                            }
                          </div>
                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claim(2)}
                              title="Clicking this button will claim your rewards but keep tokens staked for another session."
                            >
                              Claim BYAS
                            </button>
                          </div>
                          <div className="col">
                            <button className="btn my-2 btn_gray_white2" disabled={(LP3.userInfo["amount"] ? parseFloat(LP3.userInfo["amount"]) : 0) ? false : true} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => { setcurrentPool(LP3); setstakedInput("") }}>
                              Unstake
                            </button>
                          </div>

                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claimAll((LP3.userInfo["amount"] ? LP3.userInfo["amount"] : 0), 2)}
                              title="Clicking this button will claim your rewards and your staked tokens"
                            >
                              Claim All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button className="accordion-button collapsed d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      <div className="d-flex align-items-center mb-3 col-lg-4">
                        <img
                          src="/5.png"
                          alt="coin"
                          className="bg-white"
                          style={{ height: "4em", borderRadius: "15px" }}
                        />
                        <div className="ms-2 me-4 sm_font">
                          <p className="mb-1">BNB-DXO</p>
                          <div>
                            <span className="color1">Liquidity: </span>
                            <span className="text-light">${liquidity[3]}</span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 sm_font col-lg-1 col-6">
                        <p className="mb-1">{LP4.userInfo["amount"] ? LP4.userInfo["amount"].toFixed(4) : 0}</p>
                        <p className="color1 mb-0">Staked</p>
                      </div>
                      <div className="mb-3 sm_font col-lg-1 col-6" title="All yield farming APRs are unknown. This APR is simply a reflection of the last 24 hours of activity and doesn&#39;t constitute or imply a guaranteed rate of return">
                        <p className="mb-1 color2">
                          {/* <b>{APR[3]}%</b> */}
                          <b>300+%</b>
                        </p>
                        <p className="color1 mb-0">APR</p>
                      </div>
                      <div className="d-flex my-4 mx-lg-4 mx-md-4 header_btns">
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Claim
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Stake
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_orange">
                          Add liquidity
                        </button>
                      </div>
                      <button className="btn btn-sm py-lg-2 icn_btn angle_down">
                        <FaAngleDown className="size_icn" />
                      </button>
                      <button className="btn btn-sm py-lg-2 icn_btn d-none angle_up">
                        <FaAngleUp className="size_icn" />
                      </button>
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="container-fluid">
                        <div className="row mt-5 font1">

                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <img
                                    src="/BNB.png"
                                    width="22px"
                                    className="me-3 borderR-5"
                                  />
                                  <span>Total pooled BNB</span>
                                </div>
                                <div className="mb-3">
                                  <img
                                    src="/dxtc.png"
                                    width="22px"
                                    className="me-3 borderR-5 bg-white"
                                  />
                                  <span>Total pooled DXO</span>
                                </div>
                                <div className="mb-3">
                                  <ImPriceTag size={20} className="me-3" />
                                  <span>Price</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP4.getReserve["1"] ? LP4.getReserve["1"] : 0}</div>
                                <div className="mb-3 text-right">{LP4.getReserve["0"] ? LP4.getReserve["0"] : 0}</div>
                                <div className="mb-3 text-right">
                                  1 <span className="color1">BNB</span> = {LP4.price ? LP4.price : 0}{" "}
                                  <span className="color1">DXO</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Total staked</span>
                                </div>
                                <div className="mb-3">
                                  <IoMdTimer size={20} className="me-3" />
                                  <span>Time left</span>
                                </div>
                                <div className="mb-3">
                                  <VscLiveShare size={20} className="me-3" />
                                  <span>Your pool share</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP4.totalStaked ? LP4.totalStaked : 0}</div>
                                <div className="mb-3 text-right">{parseInt(LP4.userInfo["nextHarvestUntil"]) ? <CountDown4 eventTime={parseInt(LP4.userInfo["nextHarvestUntil"])} /> : "00 : 00 : 00"}</div>
                                <div className="mb-3 text-right">{LP4.share && LP4.share != "NaN" ? LP4.share : 0}%</div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your unstaked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your staked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your earned</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP4.unStake ? LP4.unStake.toFixed(8) : 0}</div>
                                <div className="mb-3 text-right">{LP4.userInfo["amount"] ? LP4.userInfo["amount"].toFixed(4) : 0}</div>
                                <div className="mb-3 text-right">
                                  <span className="color2">{(LP4.pendingToken ? LP4.pendingToken : 0)}</span>
                                  <span className="color1"> BYAS</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col">
                            <a href={`https://pancakeswap.finance/add/BNB/0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4`} className="btn my-2 btn_orange2" target="_blank">
                              Add liquidity
                            </a>
                          </div>
                          <div className="col">
                            {LP4.approve
                              ? <button className="btn my-2 btn_gray_white2" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => { setcurrentPool(LP4); setstakedInput("") }}>
                                Stake LP Tokens
                              </button>
                              : <button className="btn my-2 btn_gray_white2" onClick={() => approveToken(3)}>
                                Approve LP Tokens
                              </button>
                            }

                          </div>
                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claim(3)}
                              title="Clicking this button will claim your rewards but keep tokens staked for another session."
                            >
                              Claim BYAS
                            </button>
                          </div>
                          <div className="col">
                            <button className="btn my-2 btn_gray_white2" disabled={(LP4.userInfo["amount"] ? parseFloat(LP4.userInfo["amount"]) : 0) ? false : true} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => { setcurrentPool(LP4); setstakedInput("") }}>
                              Unstake
                            </button>
                          </div>

                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claimAll((LP4.userInfo["amount"] ? LP4.userInfo["amount"] : 0), 3)}
                              title="Clicking this button will claim your rewards and your staked tokens"
                            >
                              Claim All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      <div className="d-flex align-items-center mb-3 col-lg-4">
                        <img
                          src="/1.png"
                          alt="coin"
                          className="bg-white"
                          style={{ height: "4em", borderRadius: "15px" }}
                        />
                        <div className="ms-2 me-4 sm_font">
                          <p className="mb-1">HUNDRED-liDXO</p>
                          <div>
                            <span className="color1">Liquidity: </span>
                            <span className="text-light">${liquidity[4]}</span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 sm_font col-lg-1 col-6">
                        <p className="mb-1">{LP5.userInfo["amount"] ? LP5.userInfo["amount"].toFixed(4) : 0}</p>
                        <p className="color1 mb-0">Staked</p>
                      </div>
                      <div className="mb-3 sm_font col-lg-1 col-6" title="This pool is no longer earning BYAS rewards due to low adoption">
                        <p className="mb-1 color2">
                          <b>100+%</b>
                        </p>
                        <p className="color1 mb-0">APR</p>
                      </div>
                      <div className="d-flex my-4 mx-lg-4 mx-md-4 header_btns">
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Claim
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Stake
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_orange">
                          Add liquidity
                        </button>
                      </div>
                      <button className="btn btn-sm py-lg-2 icn_btn angle_down">
                        <FaAngleDown className="size_icn" />
                      </button>
                      <button className="btn btn-sm py-lg-2 icn_btn d-none angle_up">
                        <FaAngleUp className="size_icn" />
                      </button>
                    </button>
                  </h2>
                  <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="container-fluid">
                        <div className="row mt-5 font1">

                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <img
                                    src="/images/hundred.jpeg"
                                    width="22px"
                                    className="me-3 borderR-5"
                                  />
                                  <span>Total pooled HUNDRED</span>
                                </div>
                                <div className="mb-3">
                                  <img
                                    src="/images/liDXO.jpeg"
                                    width="22px"
                                    className="me-3 borderR-5 bg-white"
                                  />
                                  <span>Total pooled liDXO</span>
                                </div>
                                <div className="mb-3">
                                  <ImPriceTag size={20} className="me-3" />
                                  <span>Price</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP5.getReserve["0"] ? LP5.getReserve["0"] : 0}</div>
                                <div className="mb-3 text-right">{LP5.getReserve["1"] ? LP5.getReserve["1"] : 0}</div>
                                <div className="mb-3 text-right">
                                  1 <span className="color1">HUNDRED</span> = {LP5.price ? LP5.price : 0}{" "}
                                  <span className="color1">liDXO</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Total staked</span>
                                </div>
                                <div className="mb-3">
                                  <IoMdTimer size={20} className="me-3" />
                                  <span>Time left</span>
                                </div>
                                <div className="mb-3">
                                  <VscLiveShare size={20} className="me-3" />
                                  <span>Your pool share</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP5.totalStaked ? LP5.totalStaked : 0}</div>
                                <div className="mb-3 text-right">{parseInt(LP5.userInfo["nextHarvestUntil"]) ? <CountDown5 eventTime={parseInt(LP5.userInfo["nextHarvestUntil"])} /> : "00 : 00 : 00"}</div>
                                
                                <div className="mb-3 text-right">{LP5.share && LP5.share != "NaN" ? LP5.share : 0}%</div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your unstaked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your staked LP</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your earned</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP5.unStake ? LP5.unStake.toFixed(8) : 0}</div>
                                <div className="mb-3 text-right">{LP5.userInfo["amount"] ? LP5.userInfo["amount"].toFixed(4) : 0}</div>
                                <div className="mb-3 text-right">
                                  <span className="color2">{(LP5.pendingToken ? LP5.pendingToken : 0)}</span>
                                  <span className="color1"> BYAS</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col">
                            <a href={`https://pancakeswap.finance/add/0xB66279cE85Ff79dF18901946e65216777b093967/0xf3e7e95494ca1aea3e0e93b77c90c56000e2c9c4`} className="btn my-2 btn_orange2" target="_blank">
                              Add liquidity
                            </a>
                          </div>
                          <div className="col">
                            {LP5.approve
                              ? <button className="btn my-2 btn_gray_white2" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => { setcurrentPool(LP5); setstakedInput("") }}>
                                Stake LP Tokens
                              </button>
                              : <button className="btn my-2 btn_gray_white2" onClick={() => approveToken(5)}>
                                Approve LP Tokens
                              </button>
                            }

                          </div>
                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claim(5)}
                            >
                              Claim BYAS
                            </button>
                          </div>
                          <div className="col">
                            <button className="btn my-2 btn_gray_white2" disabled={(LP5.userInfo["amount"] ? parseFloat(LP5.userInfo["amount"]) : 0) ? false : true} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => { setcurrentPool(LP5); setstakedInput("") }}>
                              Unstake
                            </button>
                          </div>

                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claimAll((LP5.userInfo["amount"] ? LP5.userInfo["amount"] : 0), 5)}
                            >
                              Claim All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      <div className="d-flex align-items-center mb-3 col-lg-4">
                        <img
                          src="/images/hundred.jpeg"
                          alt="coin"
                          className="bg-white"
                          style={{ height: "4em", borderRadius: "15px" }}
                        />
                        <div className="ms-2 me-4 sm_font">
                          <p className="mb-1">HUNDRED</p>
                          <div>
                            <span className="color1">Liquidity: </span>
                            <span className="text-light">NA</span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 sm_font col-lg-1 col-6">
                        <p className="mb-1">{LP6.userInfo["amount"] ? LP6.userInfo["amount"].toFixed(4) : 0}</p>
                        <p className="color1 mb-0">Staked</p>
                      </div>
                      <div className="mb-3 sm_font col-lg-1 col-6" title="This pool is no longer earning BYAS rewards due to low adoption">
                        <p className="mb-1 color2">
                          <b>100+%</b>
                        </p>
                        <p className="color1 mb-0">APR</p>
                      </div>
                      <div className="d-flex my-4 mx-lg-4 mx-md-4 header_btns">
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Claim
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_gray_white">
                          Stake
                        </button>
                        <button className="btn mx-md-2 mx-lg-2 btn_orange">
                          Buy HUNDRED
                        </button>
                      </div>
                      <button className="btn btn-sm py-lg-2 icn_btn angle_down">
                        <FaAngleDown className="size_icn" />
                      </button>
                      <button className="btn btn-sm py-lg-2 icn_btn d-none angle_up">
                        <FaAngleUp className="size_icn" />
                      </button>
                    </button>
                  </h2>
                  <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="container-fluid">
                        <div className="row mt-5 font1">

                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <img
                                    src="/images/hundred.jpeg"
                                    width="22px"
                                    className="me-3 borderR-5"
                                  />
                                  <span>Total pooled HUNDRED</span>
                                </div>
                                <div className="mb-3">
                                  <ImPriceTag size={20} className="me-3" />
                                  <span>Price</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">NA</div>
                                <div className="mb-3 text-right">
                                  1 <span className="color1">HUNDRED</span> = {LP6.price ? LP6.price : 0}{" "}
                                  <span className="color1">$</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Total staked</span>
                                </div>
                                <div className="mb-3">
                                  <IoMdTimer size={20} className="me-3" />
                                  <span>Time left</span>
                                </div>
                                <div className="mb-3">
                                  <VscLiveShare size={20} className="me-3" />
                                  <span>Your pool share</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP6.totalStaked ? LP6.totalStaked : 0}</div>
                                <div className="mb-3 text-right">{parseInt(LP6.userInfo["nextHarvestUntil"]) ? <CountDown5 eventTime={parseInt(LP6.userInfo["nextHarvestUntil"])} /> : "00 : 00 : 00"}</div>

                                <div className="mb-3 text-right">{LP6.share && LP6.share != "NaN" ? LP6.share : 0}%</div>
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex justify-content-between">
                              <div className="color1">
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your unstaked HUNDRED</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your staked HUNDRED</span>
                                </div>
                                <div className="mb-3">
                                  <HiOutlineRectangleStack
                                    size={20}
                                    className="me-3"
                                  />
                                  <span>Your earned</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between">
                                <div className="mb-3 text-right">{LP6.unStake ? LP6.unStake.toFixed(8) : 0}</div>
                                <div className="mb-3 text-right">{LP6.userInfo["amount"] ? LP6.userInfo["amount"].toFixed(4) : 0}</div>
                                <div className="mb-3 text-right">
                                  <span className="color2">{(LP6.pendingToken ? LP6.pendingToken : 0)}</span>
                                  <span className="color1"> BYAS</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col">
                            <a href={`https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xB66279cE85Ff79dF18901946e65216777b093967`} className="btn my-2 btn_orange2" target="_blank">
                              Buy HUNDRED
                            </a>
                          </div>
                          <div className="col">
                            {LP6.approve
                              ? <button className="btn my-2 btn_gray_white2" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => { setcurrentPool(LP6); setstakedInput("") }}>
                                Stake HUNDRED
                              </button>
                              : <button className="btn my-2 btn_gray_white2" onClick={() => approveToken(6)}>
                                Approve HUNDRED
                              </button>
                            }

                          </div>
                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claim(6)}
                              title="Clicking this button will claim your rewards but keep tokens staked for another session."
                            >
                              Claim BYAS
                            </button>
                          </div>
                          <div className="col">
                            <button className="btn my-2 btn_gray_white2" disabled={(LP6.userInfo["amount"] ? parseFloat(LP6.userInfo["amount"]) : 0) ? false : true} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => { setcurrentPool(LP6); setstakedInput("") }}>
                              Unstake
                            </button>
                          </div>

                          <div className="col">
                            <button
                              className="btn my-2 me-1 btn_gray_white2"
                              onClick={() => claimAll((LP6.userInfo["amount"] ? LP6.userInfo["amount"] : 0), 6)}
                              title="Clicking this button will claim your rewards and your staked tokens"
                            >
                              Claim All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Staked</h5>
                    <button type="button" class="btn-close" id="btn-close1" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    {currentPool.approve
                      ? <div className="py-5 color1">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <img
                              src={currentPool.image}
                              alt="coin"
                              style={{ height: "2em" }}
                            /><p className="ms-3 mb-0">{currentPool.name}</p>
                          </div>
                          <p className="mb-0">Balance : {currentPool.balance ? (currentPool.balance / 10 ** 18).toFixed(2) : 0.00}</p>
                        </div>
                        <div className="d-flex">
                          <input className="stakedInput w-100 " type="number" value={stakedInput} onChange={(e) => changeStakedAmount(e.target.value)} placeholder="0.0" />
                        </div>
                        {(parseInt(stakedInput * 10 ** 18) > currentPool.balance ? (currentPool.balance) : 0.00)
                          ? <button className="btn my-2 me-1 btn_gray_white2">Insufficent Funds</button>
                          : <button className="btn mt-3 py-2 w-100  btn_orange font2" onClick={() => stakeBtn(currentPool.id)}>Stake</button>
                        }

                      </div>
                      : <div className="py-5">
                        <h3>Loading ...</h3>
                      </div>

                    }

                  </div>
                </div>
              </div>
            </div>

            <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Unstaked</h5>
                    <button type="button" class="btn-close" id="btn-close1" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    {currentPool.name
                      ? <div className="py-5 color1">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <img
                              src={currentPool.image}
                              alt="coin"
                              style={{ height: "2em" }}
                            /><p className="ms-3 mb-0">{currentPool.name}</p>
                          </div>
                          <p className="mb-0">Staked Amount : {currentPool.userInfo["amount"] ? currentPool.userInfo["amount"].toFixed(4) : 0}</p>
                        </div>
                        <div className="d-flex">
                          <input className="stakedInput w-100 " type="number" value={stakedInput} onChange={(e) => setstakedInput(e.target.value)} placeholder="0.0" />
                          <button className="btn mt-4 mb-2 px-0 ms-2 btn_orange" onClick={() => setstakedInput(toPlainString(parseFloat(currentPool.userInfo["amount"] ? currentPool.userInfo["amount"].toFixed(18) : 0)))}>max</button>
                        </div>
                        {(parseInt(stakedInput) > currentPool.userInfo["amount"] ? currentPool.userInfo["amount"] : 0)
                          ? <button className="btn my-2 me-1 btn_gray_white2">Insufficent Funds</button>
                          : <button className="btn mt-3 py-2 w-100  btn_orange font2" onClick={() => unStakeBtn(currentPool.id)}>Unstake</button>
                        }

                      </div>
                      : <div className="py-5">
                        <h3>No reward Available</h3>
                      </div>

                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {
        loading
          ? < div className="loader_outer" >
            <div className="loader"></div>
            <p>Please Wait</p>
          </div >
          : null
      } */}
    </>
  );
}
export default React.memo(Pools)