import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar";
import Burn from '../components/Burn'
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useWallet } from "@binance-chain/bsc-use-wallet";

function Dashboard() {
    const { account } = useWallet();

    const [agree, setAgree] = useState(false);
    let themeName = localStorage.getItem("theme");
    const [theme, setTheme] = useState(themeName ? themeName : "dark");

    const themeHandle = () => {
        const style = document.getElementById("theme-changer");
        if (theme === "dark") {
            style.href = "../themes/light.css";
            localStorage.setItem("theme", "light");
            setTheme("light")
        } else {
            style.href = "../themes/dark.css";
            localStorage.setItem("theme", "dark");
            setTheme("dark")
        }
    };

    useEffect(() => {
        getGraphData()
    }, [account])

    const getGraphData = async () => {
        if (account) {
            await axios
                .get(`https://api.byas.app/api/agreement/${account}`)
                .then(async (response) => {
                    console.log(response.data.data.agreement)
                    setAgree(!response.data.data.agreement)
                })
                .catch((error) => {
                    console.log(error.response)
                })
        }
    }

    const setLocation = async () => {
        try {
            await axios
                .post("https://api.byas.app/api/setAgreement", {
                    address: account,
                    agreement: true
                })
                .then((response) => {
                    console.log(response);
                    setAgree(false)
                })
                .catch((error) => {
                    console.log(error.response);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Navbar search={false} theme={theme} themeHandle={themeHandle} />
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 d-lg-block d-none">
                        <SideBar />
                    </div>
                    <div className="col-lg-10">
                        <Burn />
                    </div>
                </div>
                <div>
                    <h3 className="text-center mb-5">Our Partners</h3>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-4 col-8 my-2 text-center">
                            <a href="https://www.livecoinwatch.com/price/DXO-__DXO" target="_blank">
                                <img src={theme == "dark" ? "/images/Live.png" : "/images/Live1.png"} className="patnersLogo2" />
                            </a>
                        </div>
                        {/* <div className="col-lg-4 col-4 my-2 text-center">
                            <a href="https://nomics.com/assets/dxo3-deflationary-xchange-output" target="_blank">
                                <img className="" src="/Nomics_Graphicmark-Purple_200x200.png" height="50px" />
                            </a>
                        </div>
                        <div className="col-lg-4 col-6 my-2 text-center">
                            <a href="https://coinranking.com/coin/kPHPU-z4i+deflationaryxchangeoutput-dxo" target="_blank">
                                <img className="patnersLogo2" src={theme == "dark" ? "/images/coinranking.png" : "/images/coinranking1.png"} />
                            </a>
                        </div> */}
                        <div className="col-lg-4 col-6 my-2 text-center">
                            <a href="https://bscscan.com/token/0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4" target="_blank">
                                <img className="patnersLogo p-3" src={theme == "dark" ? "/images/bsc.png" : "/images/bsc1.png"} />
                            </a>
                        </div>
                        <div className="col-lg-4 col-6 my-2 text-center">
                            <a href="https://dappradar.com/binance-smart-chain/high-risk/byas" target="_blank">
                                <img className="patnersLogo" src={theme == "dark" ? "/images/dapp.png" : "/images/dapp1.png"} />
                            </a>
                        </div>
                        <div className="col-lg-4 col-6 my-2 text-center">
                            <a href="https://dexscreener.com/bsc/0x468ef4a41ac9b7aa4054ad1d3d8ecd516dce74f4" target="_blank">
                                <img className="patnersLogo dexScreener" src={theme == "dark" ? "/images/dex-screener-light.png" : "/images/dex-screener-dark.png"} />
                            </a>
                        </div>
                        <div className="col-lg-4 col-6 my-2 text-center">
                            <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0x468ef4a41ac9b7aa4054ad1d3d8ecd516dce74f4" target="_blank">
                                <img className="patnersLogo" src={theme == "dark" ? "/images/dextool-light.png" : "/images/dextool-dark.png"} />
                            </a>
                        </div>
                        <div className="col-lg-4 col-6 my-2 text-center">
                            <a href="https://www.dexview.com/bsc/0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4" target="_blank">
                                <img className="patnersLogo" src={theme == "dark" ? "/images/dex-light.png" : "/images/dex-dark.png"} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {agree
                ? <div class="modalCustom fade show" id="exampleModal4">
                    <div class="modalCustomchild modal-dialog modal-dialog-centered color1">
                        <div class="modal-content p-4">
                            <div class="modal-header">
                                <h5 class="modal-title text-center pb-3 agreementTitle" id="exampleModalLabel"><center>Agreement</center></h5>
                            </div>
                            <div class="modal-body">
                                <p className="text-center">Crypto is strictly prohibited in the following countries and thus DXO or any part of its services isn't available in Algeria, Bangladesh, China, Egypt, Iraq, Morocco, Nepal, North Korea, Qatar, Tunisia. Other countries including the United States also have restrictions you need to be aware of. DXO does not offer any part of it's services to anyone in restricted regions.</p>
                                <div className="text-center">
                                    <button className="btn px-4 mt-3 py-2 btn_m_s no-rap " onClick={setLocation} ><b>Accept</b></button>
                                    <Link to="/" className="btn px-4 mt-3 py-2 btn_m_s no-rap ms-4"  ><b>Decline</b></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    );
}

export default Dashboard;
