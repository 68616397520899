export default {
  DXTC: {
    56: "0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4",
    97: "0x2816E2D05EA12d790A3Df1A791d532783361ABE2",
  },
  CRANK: {
    56: "0x5DE56c867692277fAaF6756D6d30Eba2086E948E",
    97: "0x6ED852dC8Cdf90c895C05585135B1bE6b876b65b",
  },
  LP_1: {
    56: "0xd3283fA653CC0B32E33D2B578c0E41257075c1E3",
    97: "0x1d67bf0f3aD8693CFeDeEb107C5EC2be66827a29",
  },
  LP_2: {
    56: "0x468ef4a41ac9b7Aa4054AD1D3D8ecd516dCe74f4",
    97: "0xFF84710185B9d642474a83F3Cf83b8D5b6313909",
  },
  LP_3: {
    56: "0x6128F6c0a0330d7eC2575Bd9843dcC6bED922B34",
    97: "0x57Ab9D1C9606F00946BBFb7E90142AF587eb46F9",
  },
  LP_4: {
    56: "0xcED6ee6Ae1Cd3c102b2c00e7Fa84657A4f48fE71",
    97: "0x69eF300C10D76B469AE9978B8705F657893C7b2D",
  },
  LP_5: {
    56: "0x6f308429cc5392d911bdda3c955e7237b4c77194",
    97: "0x3cB23c7511FD65c064a2e357c2A67e28b828d71B",
  },
  LP_6: {
    56: "0xB66279cE85Ff79dF18901946e65216777b093967",
    97: "",
  },
  hundredBusd: {
    56: "0x2D8b5B50033554d2645ffb782b4b96a1da994150",
    97: "",
  },
  BTCB: {
    56: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    97: "0x95C2E87f4Ec3E97558C1306faF155a3Ae7576Ded",
  },
  WBNB: {
    56: "",
    97: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
  },
  Burn: {
    56: "0xF6E05Fd0925009c0D18C9DCDc1e206e4474B2E33",
    97: "0x6FFc7096E565BF2F1Fd48f66BB8C6E3B8CBd2F8E",
  },
  Staking: {
    56: "0x84D8334aBb63c99f019D9B225cD5bC28248ec403",
    97: "0x94f32f2585621e0bFDdb7A74878244a4959689f6",
  },
  LiDXO: {
    56: "0xf3e7e95494CA1AEa3E0e93b77c90c56000e2C9c4",
    97: "0xf3e7e95494CA1AEa3E0e93b77c90c56000e2C9c4",
  },
};
