import Pools from '../components/Pools'

function Home() {
    return (
        <>

            <Pools />

        </>
    );
}

export default Home;
