import addresses from "../config/constants/contracts";

const chainId = process.env.REACT_APP_CHAIN_ID;

export const getDXTCAddress = () => {
  return addresses.DXTC[chainId];
};

export const getCRANKAddress = () => {
  return addresses.CRANK[chainId];
};

export const getLP_1Address = () => {
  return addresses.LP_1[chainId];
};

export const getLP_2Address = () => {
  return addresses.LP_2[chainId];
};

export const getLP_3Address = () => {
  return addresses.LP_3[chainId];
};

export const getLP_4Address = () => {
  return addresses.LP_4[chainId];
};

export const getLP_5Address = () => {
  return addresses.LP_5[chainId];
};

export const getLP_6Address = () => {
  return addresses.LP_6[chainId];
};

export const getHundredBusdAddress = () => {
  return addresses.hundredBusd[chainId];
};

export const getBurnAddress = () => {
  return addresses.Burn[chainId];
};

export const getStakingAddress = () => {
  return addresses.Staking[chainId];
};

export const getBTCBAddress = () => {
  return addresses.BTCB[chainId];
};

export const getWBNBAddress = () => {
  return addresses.WBNB[chainId];
};

export const getLiDXOAddress = () => {
  return addresses.LiDXO[chainId];
};
