import React from "react";
import { BsStack } from "react-icons/bs";
import { MdOutlineDashboard } from "react-icons/md";
 import {SlDocs} from "react-icons/sl"
import { NavLink } from "react-router-dom";
import {BsArrowRight} from "react-icons/bs"

export default function SideBar(props) {
  return (
    <>
      <div className="sidebar mt-5">
        <ul type="none" className="px-0">
          <li className="items mb-3">
            <NavLink
              to="/stats"
              onClick={props.close}
              className={({ isActive }) =>
                isActive
                  ? "d-flex  align-items-center p-3 activeNav"
                  : "d-flex  align-items-center p-3"
              }
            >
              <MdOutlineDashboard size={25} />
              <p className="mb-0 px-4">Forge</p>
            </NavLink>
          </li>
          <li className="items mb-3">
            <NavLink
              to="/pools"
              onClick={props.close}
              className={({ isActive }) =>
                isActive
                  ? "d-flex  align-items-center p-3 activeNav"
                  : "d-flex  align-items-center p-3"
              }
            >
              <BsStack size={25} />
              <p className="mb-0 px-4">Pools</p>
            </NavLink>
          </li>
          <li className="items mb-3">
            <a
              href="https://docs.dxocoin.com/"
              target="_blank"
              className="d-flex  align-items-center p-3"
              onClick={props.close}
            >
              <SlDocs size={25} />
              <p className="mb-0 ps-4 pe-2 pt-2 ">Docs </p>
              <img src="/arrow2.png" className="ms-2 arrowImg" width={25}/>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
