import { useEffect, useState } from "react";
import { AbiItem } from "web3-utils";
import { ContractOptions } from "web3-eth-contract";
import useWeb3 from "../hooks/useWeb3";
import DXTCABI from "../abi/DXTC.json";
import CRANKABI from "../abi/CRANK.json";
import LP from "../abi/LP.json"
import BurnABI from "../abi/Burn.json"
import BTCBABI from "../abi/BTCB.json"
import WBNBABI from "../abi/WBNB.json"
import StakingABI from "../abi/Staking.json";
import LiDXOABI from "../abi/LiDXO.json";
import {
  getDXTCAddress,
  getCRANKAddress,
  getLP_1Address,
  getLP_2Address,
  getLP_3Address,
  getLP_4Address,
  getLP_5Address,
  getLP_6Address,
  getBurnAddress,
  getStakingAddress,
  getBTCBAddress,
  getWBNBAddress,
  getLiDXOAddress,
  getHundredBusdAddress
} from "../utils/addressHelpers";

const useContract = (abi, address, contractOptions) => {
  const web3 = useWeb3();
  const [contract, setContract] = useState(
    new web3.eth.Contract(abi, address, contractOptions)
  );

  useEffect(() => {
    setContract(new web3.eth.Contract(abi, address, contractOptions));
  }, [abi, address, contractOptions, web3]);

  return contract;
};

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useDXTC = () => {
  const abi = DXTCABI.abi;
  return useContract(abi, getDXTCAddress());
};

export const useCRANK = () => {
  const abi = CRANKABI.abi;
  return useContract(abi, getCRANKAddress());
};

export const useLP_1 = () => {
  const abi = LP.abi;
  return useContract(abi, getLP_1Address());
};

export const useLP_2 = () => {
  const abi = LP.abi;
  return useContract(abi, getLP_2Address());
};

export const useLP_3 = () => {
  const abi = LP.abi;
  return useContract(abi, getLP_3Address());
};

export const useLP_4 = () => {
  const abi = LP.abi;
  return useContract(abi, getLP_4Address());
};

export const useLP_5 = () => {
  const abi = LP.abi;
  return useContract(abi, getLP_5Address());
};

export const useLP_6 = () => {
  const abi = LP.abi;
  return useContract(abi, getLP_6Address());
};

export const useHundredBusd = () => {
  const abi = LP.abi;
  return useContract(abi, getHundredBusdAddress());
};

export const useBurn = () => {
  const abi = BurnABI.abi;
  return useContract(abi, getBurnAddress());
};

export const useStaking = () => {
  const abi = StakingABI.abi;
  return useContract(abi, getStakingAddress());
};

export const useBTCB = () => {
  const abi = BTCBABI.abi;
  return useContract(abi, getBTCBAddress());
};

export const useWBNB = () => {
  const abi = WBNBABI.abi;
  return useContract(abi, getWBNBAddress());
};

export const useLiDXO = () => {
  const abi = LiDXOABI.abi;
  return useContract(abi, getLiDXOAddress());
};

export default useContract;
