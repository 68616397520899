import React from "react";
import getRpcUrl from "./utils/getRpcUrl";
import * as bsc from "@binance-chain/bsc-use-wallet";

const Providers = ({ children }) => {
  const rpcUrl = getRpcUrl();
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
  return (
    <bsc.UseWalletProvider
      chainId={chainId}
      connectors={{
        walletconnect: { rpcUrl },
        bsc,
      }}
    >
      {children}
    </bsc.UseWalletProvider>
  );
};

export default Providers;
