import { Link } from "react-router-dom";
import { BsTwitter, BsTelegram } from "react-icons/bs"

function Launch2() {

    return (
        <>
            <div className="bg-black launch">
                <div className="container">
                    <header className="row align-items-center justify-content-between py-4 header">
                        <div className="col-lg-1 col-md-2 col-1 mt-3">
                            <img src="/images/LaunchLogo.png" />
                        </div>
                        <div className="col-lg-10 col-md-10 col-11 mt-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <a className="text-white d-flex me-lg-4 me-md-4 me- align-items-center" href="https://bscscan.com/token/0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4" target="_blank"><img src="/images/bscSmall.png" className="img" /><span className="d-lg-block d-none ms-2 addressText">(0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4)</span></a>
                                <a className="text-white" href="https://t.me/BYASOfficial" target="_blank"><BsTelegram className="me-lg-5 me-md-4 me-2 img" color="#fff" /></a>
                                <a className="text-white" href="https://twitter.com/Byas_Community" target="_blank"><BsTwitter className="me-lg-5 me-md-4 me-2 img" color="#fff" /></a>
                                <Link to="/stats" className="launchBtn me-lg-3 me-md-3 me-1">Launch APP</Link>
                                <a href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&amp;outputCurrency=0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4" target="_blank" className="launchBtn">Buy DXO</a>
                            </div>
                        </div>
                    </header>
                </div>
                <div className="dxoAnimatedBg">
                    <div className="container">
                        <div className="row align-items-center justify-content-between py-3">
                            <div className="col-lg-6 col-md-8 my-5">
                                <div className="btcContent1 my-5">
                                    <h2>Decentralized Stable Asset</h2>
                                    <h1>based on real market behavior</h1>
                                    <p><b>DXO</b> is the FIRST Decentralized Stable Asset that can only be created by burning BTC and BNB. Holders get a piece of every transaction on the DXO network. As the network grows so does your DXO balance. Early stakers get even more rewards. <Link to="/stats" className="launchLink">Launch APP</Link></p>
                                    <div>
                                        <a href="https://docs.dxocoin.com/" target="_blank" className="launchBtn launchBtn1 mt-5">Read the whitepaper </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">

                    <div className="row align-items-center py-5">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div id="cf">
                                <img src="/images/btcCoin.png" className="w-100 bottom" />
                                <img src="/images/btcCoinlight.png" className="w-100 top" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="btcContent my-5">
                                <h1>Introducing the Bitcoin Standard</h1>
                                <p>All collateralized stable coins will eventually die.</p>
                                <p>Let's use Bitcoin to <b>FORGE</b> the hardest most reliable decentralized stable asset ever.</p>
                                <p><b>DXO</b> is the first decentralized stable asset that can only be created in the FORGE by burning BTC.</p>
                                <p>Every single <b>DXO</b> is instantly verifiable with no accounting and no banks necessary since it was created on chain by burning Bitcoin.</p>
                                <p><b>DXO</b> is impervious to changes in the crypto market since it's not a collateralized asset.</p>
                                <a href="https://docs.dxocoin.com/" target="_blank" className="launchBtn launchBtn1 mt-5">Read the whitepaper </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Launch2;
